import React, { useContext } from 'react';
import { Context } from 'components/Store';
import PropTypes from 'prop-types';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import PropertyLandingContext from 'screens/Property/PropertiesLanding/context';
import usePropertyFilters from 'hooks/usePropertyFilters';

const PropertyBreadcrumb = ({ disabledOrgChange, disabledPropertyChange }) => {
  const [context] = useContext(Context);
  const { selectedProperty } = context;
  const {
    isLoadingProperties,
    setParams,
    onPropertySelection,
    isFarmSelected,
    isFieldSelected,
    isHugeOrganization
  } = useContext(PropertyLandingContext);
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  const { filteredProperties } = usePropertyFilters(
    undefined,
    isHugeOrganization
  );

  const getBreadcrumbTitle = () => {
    if (isEnableInfoTabEdit) {
      if (isFarmSelected) {
        return 'Edit Farm';
      }
      if (isFieldSelected) {
        return `Edit Field info: ${selectedProperty?.name}`;
      }
    }
    if (!selectedProperty?.id) {
      return 'All Properties';
    }
    if (isFarmSelected) {
      return selectedProperty?.name;
    }
    return (
      filteredProperties.find(farm => farm.id === selectedProperty.farmId)
        ?.name || selectedProperty?.name
    );
  };

  const title = getBreadcrumbTitle();

  const handleOrganizationSelect = org => {
    onPropertySelection();
    setParams({ page: 0, orgId: org?.id });
  };

  return (
    <Breadcrumb
      onOrganizationSelect={handleOrganizationSelect}
      disabled={disabledOrgChange}
      disabledCropSeasons={isLoadingProperties}
      hideCropSeasonDropdown={false}
      onCropSeasonSelect={handleOrganizationSelect}
    >
      {disabledPropertyChange && (
        <Breadcrumb.Item title="Properties" value={title} isLast />
      )}
      {!disabledPropertyChange && title && (
        <Breadcrumb.Item title="Properties" value={title} />
      )}
    </Breadcrumb>
  );
};

PropertyBreadcrumb.propTypes = {
  disabledOrgChange: PropTypes.bool,
  disabledPropertyChange: PropTypes.bool
};

PropertyBreadcrumb.defaultProps = {
  disabledOrgChange: false,
  disabledPropertyChange: false
};

export default PropertyBreadcrumb;
