/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Spinner, Toast } from '@agconnections/grow-ui';
import { cloneDeep } from 'lodash';
import { useFormikContext } from 'formik';
import ProductsSideBar from 'components/ProductSideBar';
import useProductsData from '../../../../../hooks/useProductData';
import PlanProductTable from './components/PlanProductTable';

const Products = ({ memberRole, memberFinancialAccess }) => {
  const { products, loading } = useProductsData();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  // eslint-disable-next-line no-unused-vars
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);
  const [customProductCreated, setCustomProductCreated] = useState(false);
  const [stubbedAddedProducts, setStubbedAddedProducts] = useState(
    products?.slice(0, 2)
  );
  const getDisplayedProducts = () => {
    const displayedProductsArray = [];

    if (products && stubbedAddedProducts) {
      products.forEach(productCopy => {
        // If product has not been added, display in the sidebar
        if (
          stubbedAddedProducts.findIndex(
            stubbedAddedProductCopy =>
              productCopy._id === stubbedAddedProductCopy._id
          ) < 0
        ) {
          displayedProductsArray.push(productCopy);
        }
      });
    }
    return displayedProductsArray;
  };

  const displayedProducts = getDisplayedProducts();

  // useEffect(() => {
  //   if (!values?.cropZones || values?.cropZones?.length === 0) {
  //     setToastRenderContents(
  //       'Please select a property on the Property Tab before selecting products.  Costs will not be calculated until a property is selected.'
  //     );
  //     setToastHasNotBeenClosed(true);
  //   }
  // }, []);

  useEffect(() => {
    setStubbedAddedProducts(products?.slice(0, 2));
  }, [products]);

  const removeProduct = index => {
    setFieldTouched('deleted product');
    const stubbedAddedProductsCopy = cloneDeep(values?.planProductEntries);
    stubbedAddedProductsCopy.splice(index, 1);
    setStubbedAddedProducts(stubbedAddedProductsCopy);
    setFieldValue('planProductEntries', stubbedAddedProductsCopy);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex justify-between h-full">
          {toastHasNotBeenClosed ? (
            <Toast
              icon="error"
              onClose={() => {
                setToastHasNotBeenClosed(false);
              }}
            >
              {toastRenderContents}
            </Toast>
          ) : null}
          <div className="w-3/12 mt-8">
            <ProductsSideBar
              products={displayedProducts}
              memberRole={memberRole}
              memberFinancialAccess={memberFinancialAccess}
              setCustomProductCreated={setCustomProductCreated}
              productFieldName="planProductEntries"
            />
          </div>
          <div className="ml-4 flex-grow">
            <PlanProductTable
              className="mt-8"
              removeProduct={removeProduct}
              memberRole={memberRole}
              memberFinancialAccess={memberFinancialAccess}
              setCustomProductCreated={setCustomProductCreated}
              customProductCreated={customProductCreated}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Products;
