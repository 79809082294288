/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@agconnections/grow-ui';
import ClickableDiv from 'components/ClickableDiv';
import { verticalCollapse } from 'utilities/animations';
import { Radio, Tree } from 'syngenta-digital-cropwise-react-ui-kit';
import { color } from 'd3';

import './index.css';
import { RADIO_OPTIONS_FLAGS } from '../../constants';

const selectArrowIcon = open => (
  <Icon icon={open ? 'chevron-up' : 'chevron-down'} color="515253_1515253_1" />
);
const TasksTagFilter = ({
  tasksData,
  localTasksFiltersConfig,
  handleRadioButtonGroup,
  setSelectedTasksTagIds
}) => {
  const [toggleOptions, setToggleOptions] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const optionsRef = useRef();

  useEffect(() => {
    verticalCollapse(optionsRef.current, toggleOptions);
  }, [toggleOptions]);

  const extractFlagIds = (tasks, flagIds = new Set()) => {
    tasks.forEach(task => {
      task.flags?.forEach(flag => flagIds.add(flag.id));
      if (task.subtasks) extractFlagIds(task.subtasks, flagIds);
    });
    return Array.from(flagIds);
  };

  const findFlagById = (tasks, id) => {
    let foundFlag = null;
    tasks.some(task => {
      if (task.flags) {
        foundFlag = task.flags.find(flag => flag.id === id);
        if (foundFlag) return true;
      }
      if (task.subtasks) {
        foundFlag = findFlagById(task.subtasks, id);
        if (foundFlag) return true;
      }
      return false;
    });
    return foundFlag;
  };

  const transformFlagIdsToTreeData = ids =>
    ids
      .map(id => {
        const flag = findFlagById(tasksData, id);
        return flag
          ? { title: flag.name, key: flag.id, color: flag.color, children: [] }
          : null;
      })
      .filter(Boolean);

  const flagIds = extractFlagIds(tasksData);

  const filteredTreeData = transformFlagIdsToTreeData(flagIds);

  const renderTitle = node => {
    const bgColor = color(node.color);
    if (!bgColor) return null;
    return (
      <span
        className="rounded-md"
        style={{
          backgroundColor: bgColor.formatHex(),
          padding: '1.5px 10px',
          color: '#fff',
          fontWeight: 'bold'
        }}
      >
        {node.title}
      </span>
    );
  };

  return (
    <div className="pb-6">
      <ClickableDiv
        className="flex justify-between items-center px-5 overflow-y-hidden justify-center"
        onClick={() => setToggleOptions(!toggleOptions)}
      >
        <span className="text-black font-semibold">Tags</span>
        {selectArrowIcon(toggleOptions)}
      </ClickableDiv>
      <div
        className="transition-all duration-200 overflow-y-hidden border-neutral-100 px-5 h-full pt-6"
        ref={optionsRef}
        data-testid="collapsible-options"
      >
        <span className="text-neutral-60">Show work orders that contain:</span>
        <Radio.Group
          name="backgroundFieldsOptions"
          className="my-6 tasks-tag-filter-radio-group"
          value={localTasksFiltersConfig.radioOption}
          onChange={handleRadioButtonGroup}
        >
          <Radio.Button value={RADIO_OPTIONS_FLAGS.anyTags}>
            Any of the selected tags
          </Radio.Button>
          <Radio.Button value={RADIO_OPTIONS_FLAGS.allTags}>
            All selected tags
          </Radio.Button>
        </Radio.Group>
        <div className="filter-tags-container">
          <Tree
            checkable
            treeData={filteredTreeData}
            checkedKeys={localTasksFiltersConfig.showTaskTagsWithIds}
            onCheck={setSelectedTasksTagIds}
            expandedKeys={expanded}
            onExpand={setExpanded}
            selectable={false}
            showIcon={false}
            titleRender={renderTitle}
          />
        </div>
      </div>
    </div>
  );
};

TasksTagFilter.propTypes = {
  tasksData: PropTypes.shape.isRequired,
  localTasksFiltersConfig: PropTypes.shape.isRequired,
  handleRadioButtonGroup: PropTypes.func.isRequired,
  setSelectedTasksTagIds: PropTypes.func.isRequired
};
export default TasksTagFilter;
