import React, { useContext } from 'react';

import { Context } from 'components/Store';
import createAction from 'helpers/createAction';
import { SET_MAP_STYLE_IMAGE } from 'reducers/reducer';

import MapStyleRadioGroup from 'components/MapStyleRadioGroup';
import CollapsibleSection from '../CollapsibleSection';

const BackgroundImageSection = () => {
  const [{ mapStyleImage }, dispatch] = useContext(Context);

  const handleRadioChange = e => {
    createAction(dispatch, SET_MAP_STYLE_IMAGE, e.target.value);
  };

  return (
    <CollapsibleSection title="Background map image">
      <div className="px-4 pb-4">
        <MapStyleRadioGroup
          mapStyleLocalValue={mapStyleImage}
          handleRadioChange={handleRadioChange}
        />
      </div>
    </CollapsibleSection>
  );
};

export default BackgroundImageSection;
