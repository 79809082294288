import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import useTasks from 'hooks/useTasks';
import ChecklistTree from 'components/ChecklistTree';
import NoSearchResultsImage from 'assets/no_search_results_found.svg';
import usePropertyFilters from 'hooks/usePropertyFilters';
import { FILTER_TYPE } from 'screens/Property/PropertiesLanding/components/PropertyTreeView/components/PropertyFilters/constants';

const WorkOrdersSection = ({ onOpenFilters, setTasksData }) => {
  const {
    filteredTasksByTags,
    setTaskTagsToFilter,
    hasActiveFilters
  } = usePropertyFilters(FILTER_TYPE.reports);

  const { values, setFieldValue } = useFormikContext();

  const emptyStateConfig = {
    title: 'No Work Orders Found',
    message: 'Please try a different search or filter.',
    overlayIcon: NoSearchResultsImage
  };

  const seasonIdsCommaSeparated = useMemo(
    () =>
      values.selectedCropSeasons?.map(cropSeason => cropSeason?.id).join(',') ??
      '',
    [values.selectedCropSeasons]
  );

  const cropZoneIdsCommaSeparated = useMemo(
    () => values.selectedCropzones?.map(({ id }) => id).join(',') ?? '',
    [values.selectedCropzones]
  );

  const initialPayload = { size: 1 };
  initialPayload.includeFlags = true;

  if (seasonIdsCommaSeparated.length && cropZoneIdsCommaSeparated.length) {
    initialPayload.seasonIdsCommaSeparated = seasonIdsCommaSeparated;
    initialPayload.cropZoneIdsCommaSeparated = cropZoneIdsCommaSeparated;
    initialPayload.size = 0;
  }
  const { loadTasks, loading, tasks } = useTasks(initialPayload);
  const [checked, setChecked] = useState([]);
  const [numDisplayedTasks, setNumDisplayedTasks] = useState(0);

  useEffect(() => {
    if (seasonIdsCommaSeparated.length && cropZoneIdsCommaSeparated.length) {
      loadTasks({
        cropZoneIdsCommaSeparated,
        seasonIdsCommaSeparated,
        size: 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonIdsCommaSeparated, cropZoneIdsCommaSeparated]);

  // Map API response to tree data
  const treeData = useMemo(() => {
    if (loading || !tasks?.length) return [];

    const filteredTasks = tasks.filter(task => task.status === 'ready');
    setTasksData(filteredTasks);

    return filteredTasks
      .map(task => ({
        key: task.taskId,
        title: task.name,
        flags: task.flags
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tasks, loading]);

  // Update form values with checked items
  useEffect(() => {
    if (!tasks?.length || !seasonIdsCommaSeparated.length) return;

    setFieldValue('selectedWorkOrders', checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    setTaskTagsToFilter(treeData);
  }, [treeData]);

  let numWorkOrdersDisplay = '';
  if (!loading) {
    numWorkOrdersDisplay = `(${numDisplayedTasks})`;
  }
  return (
    <div className="flex flex-col items-center bg-white">
      {seasonIdsCommaSeparated.length > 0 &&
        cropZoneIdsCommaSeparated.length > 0 && (
          <div className="w-full">
            <h1
              className="font-body font-semibold text-xl"
              style={{ color: '#14151C' }}
            >
              Ready Tasks {numWorkOrdersDisplay}
            </h1>
            <ChecklistTree
              treeData={filteredTasksByTags}
              hasActiveFilters={hasActiveFilters}
              loading={loading}
              checked={checked}
              setChecked={setChecked}
              setNumResults={setNumDisplayedTasks}
              onOpenFilters={onOpenFilters}
              enableFilters
              emptyStateConfig={emptyStateConfig}
            />
          </div>
        )}
    </div>
  );
};

WorkOrdersSection.propTypes = {
  onOpenFilters: PropTypes.func,
  setTasksData: PropTypes.func
};

WorkOrdersSection.defaultProps = {
  onOpenFilters: () => {},
  setTasksData: () => {}
};

export default WorkOrdersSection;
