import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input, Tree } from 'syngenta-digital-cropwise-react-ui-kit';
import filterOutlined from 'assets/filter_outlined.svg';
import { Spinner } from '@agconnections/grow-ui';
import {
  filterNodesBySearchFunction,
  obtainAllKeys
} from 'screens/Reports/helpers/helperFunctions';
import SearchIcon from 'components/Icons/SearchIcon';
import EmptyStateTree from './EmptyStateTree';

const ChecklistTree = ({
  treeData,
  hasActiveFilters,
  loading,
  checked,
  setChecked,
  setNumResults,
  onOpenFilters,
  enableFilters,
  emptyStateConfig
}) => {
  const [expanded, setExpanded] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const { title, message, overlayIcon } = emptyStateConfig;

  const handleSelectAll = () => {
    if (checked.length === checkedKeys.length) {
      setChecked([]);
    } else {
      setChecked(checkedKeys);
    }
  };

  const onFilterChange = e => {
    const searchText = e.target.value;
    if (searchText?.length < 3) {
      setFilteredTreeData(treeData);
      return;
    }

    const filterNodes = filterNodesBySearchFunction(searchText);
    setFilteredTreeData(treeData.reduce(filterNodes, []));
  };

  const isEmptyStateTree = useMemo(
    () => (title.length ? treeData : treeData.length),
    [title, treeData]
  );

  useEffect(() => {
    if (isEmptyStateTree) {
      setFilteredTreeData(treeData);
      setInitialLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  useEffect(() => {
    setNumResults(filteredTreeData.length);

    const newCheckedKeys = obtainAllKeys(filteredTreeData);

    setCheckedKeys(newCheckedKeys);
    if (initialLoad) {
      setChecked(newCheckedKeys);
      setInitialLoad(false);
      return;
    }
    setChecked(newCheckedKeys.filter(id => checked.includes(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTreeData]);

  const isAllSelected = checked.length === checkedKeys.length;
  const isIndeterminate =
    checked.length > 0 && checked.length < checkedKeys.length;
  return (
    <div className="filter-container">
      {!loading ? (
        <>
          {isEmptyStateTree ? (
            <>
              <div className="mt-4 mb-2 flex flex-row items-center">
                <div className="flex-grow">
                  <Input
                    className="w-full"
                    prefix={<SearchIcon />}
                    placeholder="Search..."
                    type="search"
                    onChange={onFilterChange}
                  />
                </div>

                {enableFilters && (
                  <div className="relative">
                    <button
                      data-testid="panel-filter-button"
                      type="button"
                      className="ml-2 w-8 h-8 flex items-center justify-center rounded-md hover:bg-neutral-100 bg-white border border-neutral-30"
                      onClick={onOpenFilters}
                    >
                      <img src={filterOutlined} alt="" />
                    </button>

                    {hasActiveFilters() && (
                      <div
                        className="w-10px h-10px rounded-full bg-blue-90 absolute top-0"
                        style={{ right: '-4px' }}
                      />
                    )}
                  </div>
                )}
              </div>
              {filteredTreeData.length ? (
                <>
                  <div className="mt-2 mb-1 flex">
                    <Checkbox
                      data-testid="checklist-tree-select-all"
                      size="small"
                      onChange={handleSelectAll}
                      checked={isAllSelected}
                      indeterminate={isIndeterminate}
                    >
                      Select All
                    </Checkbox>
                  </div>
                  <Tree
                    checkable
                    treeData={filteredTreeData}
                    checkedKeys={checked}
                    onCheck={setChecked}
                    expandedKeys={expanded}
                    onExpand={setExpanded}
                    selectable={false}
                    showIcon={false}
                  />
                </>
              ) : (
                <div>
                  {title.length ? (
                    <EmptyStateTree
                      title={title}
                      message={message}
                      overlayIcon={overlayIcon}
                    />
                  ) : null}
                </div>
              )}
            </>
          ) : null}
        </>
      ) : (
        <div className="mt-40px">
          <Spinner size={72} />
        </div>
      )}
    </div>
  );
};

ChecklistTree.defaultProps = {
  treeData: [],
  loading: false,
  setNumResults: () => {},
  emptyStateConfig: { title: '', message: '', overlayIcon: '' },
  onOpenFilters: () => {},
  hasActiveFilters: () => {},
  enableFilters: false
};
ChecklistTree.propTypes = {
  treeData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChecked: PropTypes.func.isRequired,
  setNumResults: PropTypes.func,
  emptyStateConfig: PropTypes.shape(),
  onOpenFilters: PropTypes.func,
  hasActiveFilters: PropTypes.func,
  enableFilters: PropTypes.bool
};

export default ChecklistTree;
