import MapStyleRadioGroup from 'components/MapStyleRadioGroup';
import { useFormikContext } from 'formik';
import { MAP_STYLE_TYPES } from 'helpers/constants';
import React, { useEffect, useState } from 'react';

const MapStyleSection = () => {
  const { setFieldValue } = useFormikContext();
  const { values } = useFormikContext();
  const [mapStyleLocalValue, setMapStyleLocalValue] = useState(
    MAP_STYLE_TYPES.satellite
  );
  const handleRadioChange = e => {
    setMapStyleLocalValue(e.target.value);
  };

  const areCropZonesSelected = values.selectedCropzones?.length > 0;
  const areSeasonsSelected = values.selectedCropSeasons?.length > 0;

  useEffect(() => {
    setFieldValue('selectedMapStyle', mapStyleLocalValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStyleLocalValue]);

  return areCropZonesSelected && areSeasonsSelected ? (
    <div className="flex flex-col items-center bg-white">
      <h1
        data-testid="product-text"
        className="font-body font-semibold text-xl w-full mb-2"
        style={{ color: '#14151C' }}
      >
        Background Map Image
      </h1>
      <MapStyleRadioGroup
        mapStyleLocalValue={mapStyleLocalValue}
        handleRadioChange={handleRadioChange}
      />
    </div>
  ) : null;
};

export default MapStyleSection;
