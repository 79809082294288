import React from 'react';
import PropTypes from 'prop-types';
import EmptyStateImage from 'assets/no_products_found.svg';

const EmptyStateTree = ({ title, message, overlayIcon }) => {
  return (
    <div
      data-testid="empty-state"
      className="w-full h-full flex flex-col justify-center items-center"
    >
      <img width={200} src={overlayIcon ?? EmptyStateImage} alt="empty-state" />
      <p
        className={`text-sm font-semibold mb-2 ${
          overlayIcon === '/static/media/noDocumentFound.e8c780e2.svg'
            ? 'mt-10'
            : null
        }`}
      >
        {title}
      </p>
      {message && (
        <p data-testid="empty-table-state-message" className="text-sm mb-4">
          {message}
        </p>
      )}
    </div>
  );
};

EmptyStateTree.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  overlayIcon: PropTypes.string
};

EmptyStateTree.defaultProps = {
  title: '',
  message: '',
  overlayIcon: ''
};

export default EmptyStateTree;
