import { useContext, useEffect, useState } from 'react';

import { organization as organizationApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';

const useMembersData = () => {
  const [, dispatch] = useContext(Context);

  const [members, setMembers] = useState([]);

  const [loading, setLoading] = useState(true);

  // Load tasks
  useEffect(() => {
    const { promise } = organizationApi.fetch();

    promise
      .then(({ data }) => {
        if (data) {
          setMembers(data.members);
        }
        setLoading(false);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { members, loading };
};

export default useMembersData;
