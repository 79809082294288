import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export const initialize = () => {
  // Initialize Datadog RUM
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'cropwise-financials-web',
    env: process.env.REACT_APP_ENVIRONMENT,
    version: '1.0.0',
    sampleRate: 50,
    trackInteractions: true,
    trackResources: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  // Initialize Datadog Logs
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sampleRate: 50
  });

  // Enable session replay
  datadogRum.startSessionReplayRecording({ force: true });
};

export const addUserAttributes = (userId, email) => {
  datadogRum.setUser({ id: userId, email });
};

export const resetUserAttributes = () => {
  datadogRum.setUser({});
};
