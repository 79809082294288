/* eslint-disable import/prefer-default-export */

export const FILTER_TYPE = {
  reports: 'reports'
};

export const RADIO_OPTIONS_FLAGS = {
  anyTags: 'anyTags',
  allTags: 'allTags'
};
