import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';

import { Context } from 'components/Store';
import CropSeasonDateRange from 'components/CropSeasonDateRange';
import CropSeasonDropdown from 'components/CropSeasonDropdown';
import useCropSeasons from 'hooks/useCropSeasons';
import { FILTER_TYPE } from 'screens/Property/PropertiesLanding/components/PropertyTreeView/components/PropertyFilters/constants';
import PropertyFilters from 'screens/Property/PropertiesLanding/components/PropertyTreeView/components/PropertyFilters';
import { isFormIncomplete } from 'screens/Reports/helpers/formTouched';
import UpdateReportButton from '../UpdateReportButton';
import PropertiesSection from '../PropertiesSection';
import ProductsSection from '../ProductsSection';
import PlansSection from '../PlansSection';
import MultiPrintAlert from '../MultiPrintAlert';
import ExcelAlert from '../ExcelAlert';
import WorkOrdersSection from '../WorkOrdersSection';
import MapStyleSection from '../MapStyleSection';

const ReportDetailsForm = ({
  activeReport,
  formConfig,
  formTouched,
  embedToken,
  exportOnly
}) => {
  const { values, setFieldValue, submitForm } = useFormikContext();
  const { cropSeasons, loaded } = useCropSeasons();
  const orgId = Context?._currentValue[0]?.organization?.id;

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [tasksData, setTasksData] = useState([]);
  const openFilters = () => setFiltersOpen(true);
  const closeFilters = () => setFiltersOpen(false);

  const submitDisabled =
    !formTouched(values) || isFormIncomplete(formConfig, values);
  const handleCropSeasonSelect = seasonSelected => {
    setFieldValue(
      'selectedCropSeasons',
      seasonSelected.filter(season => !!season)
    );
  };

  useEffect(() => {
    if (embedToken) {
      setFieldValue('initialSubmit', false);
    }
  }, [orgId, setFieldValue, embedToken]);

  useEffect(() => {
    if (values.initialSubmit || isFormIncomplete(formConfig, values)) return;

    submitForm(values);
    setFieldValue('initialSubmit', true);
  }, [formConfig, values, setFieldValue, submitForm, embedToken]);

  useEffect(() => {
    closeFilters();
  }, [formConfig]);

  return (
    <div className="z-10 w-101 pl-16px">
      <div className="w-full h-full bg-white relative">
        {filtersOpen && (
          <PropertyFilters
            properties={[]}
            tasksData={tasksData}
            onClose={closeFilters}
            type={FILTER_TYPE.reports}
          />
        )}
        <div
          className={`overflow-y-auto w-full h-full p-4 ${
            filtersOpen ? 'hidden' : ''
          }`}
        >
          <h2 className="mb-4 text-xl font-semibold text-neutral-1000">
            Report Details
          </h2>
          {formConfig?.showCropSeasons && (
            <div className="mb-6">
              <CropSeasonDropdown
                allCropSeasonsData={cropSeasons}
                csLoaded={loaded}
                onSelect={handleCropSeasonSelect}
                selectedActive
                isRequired={false}
              />
              {formConfig?.showDateRange && (
                <CropSeasonDateRange
                  isRequired={false}
                  className="flex flex-row pb-6 mt-6 gap-5 justify-between relative"
                />
              )}
            </div>
          )}
          {formConfig?.showPlans && (
            <div className="mb-12">
              <PlansSection />
            </div>
          )}
          {formConfig?.showExcelAlert && <ExcelAlert />}
          {formConfig?.showPrintAlert && (
            <MultiPrintAlert values={values} activeReport={activeReport} />
          )}
          {formConfig?.showProperties && (
            <div className="mb-12">
              <PropertiesSection />
            </div>
          )}
          {formConfig?.showWorkOrders && (
            <div className="mb-12">
              <WorkOrdersSection
                onOpenFilters={openFilters}
                setTasksData={setTasksData}
              />
            </div>
          )}
          {formConfig?.showProducts && (
            <div className="mb-12">
              <ProductsSection cropSeasons={cropSeasons} />
            </div>
          )}
          {formConfig?.showMapStyle && (
            <div className="mb-12">
              <MapStyleSection />
            </div>
          )}
        </div>
        {!exportOnly && !filtersOpen && (
          <UpdateReportButton disabled={submitDisabled} />
        )}
      </div>
    </div>
  );
};

ReportDetailsForm.propTypes = {
  activeReport: PropTypes.shape().isRequired,
  formConfig: PropTypes.shape().isRequired,
  formTouched: PropTypes.func.isRequired,
  embedToken: PropTypes.string.isRequired,
  exportOnly: PropTypes.bool.isRequired
};

export default ReportDetailsForm;
