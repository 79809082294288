import React from 'react';
import { Select } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import { SOURCE_FILTER_OPTIONS } from 'screens/ProductList/helpers/tableConstants';

const ProductListSourceFilter = ({ sourceFilters, handleFilterChange }) => {
  return (
    <div className="w-220px ml-4 font-medium max-h-8 whitespace-nowrap">
      <Select
        multiple
        value={sourceFilters.join(',')}
        placeholder="Filter by source"
        items={SOURCE_FILTER_OPTIONS}
        onChange={handleFilterChange}
      />
    </div>
  );
};

ProductListSourceFilter.propTypes = {
  sourceFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterChange: PropTypes.func.isRequired
};
export default ProductListSourceFilter;
