import Decimal from 'decimal.js';
import { Maths } from './maths';

export const roundValueTo3Decimals = valueToRound => {
  return Math.round((valueToRound + Number.EPSILON) * 1000) / 1000;
};

export const roundValue = valueToRound => {
  return Math.round((valueToRound + Number.EPSILON) * 100) / 100;
};
export const defaultProducts = product => {
  return {
    productId: product?.productId || '',
    productName: product?.productName || '',
    manufacturerName: product?.manufacturerName || '',
    areaUnit: 'acre',
    averagePriceAtTimeOfCreation: product?.specificCostValue || 0,
    averagePriceUnitAtTimeOfCreation: product?.specificCostUnit || 'gallon',
    totalProductValue: product?.totalProductValue || 0,
    totalProductUnit: product?.totalProductUnit || 'gallon',
    ratePerAreaValue: product?.productRateValue || 0,
    ratePerAreaUnit: product?.productRateUnit || 'gallon',
    ratePerTankValue: 0,
    ratePerTankUnit: product?.productRateUnit || 'gallon',
    totalCostAtTimeOfCreation: product?.totalProductCost || 0,
    coveragePercent:
      Maths.multiplyRound(
        Number.parseFloat(product.coveragePercent),
        100,
        2,
        Decimal.ROUND_HALF_UP
      ) || 100,
    appliedAreaValue: product?.areaValue || 0,
    appliedAreaUnit: product?.areaUnit || 'acre',
    applicationMethod: '',
    targetPest: {},
    specificCostPerUnit: product?.specificCostValue || 0,
    specificCostUnit: product?.specificCostUnit || 'a',
    shareOwnerInformation: {
      percentGrowerResponsibility: 100,
      totalFromGrowerInventoryQuantity: 100,
      totalFromGrowerInventoryUnit: product?.totalProductUnit || 'gallon'
    },
    productDensity: product?.productDensity || 0,
    custom: product?.custom || false,
    customProductValue: 0,
    customProductUnit: '',
    hasCost: false,
    customRateType: '',
    associatedProduct: false
  };
};

export const convertNumberToDesiredFormat = (target, format, roundedTo = 2) => {
  if (target == null) return null;
  const options = {
    currency: { style: 'currency', currency: 'USD' },
    percent: {
      style: 'percent',
      minimumFractionDigits: roundedTo,
      maximumFractionDigits: roundedTo
    },
    fixed: {
      minimumFractionDigits: roundedTo,
      maximumFractionDigits: roundedTo
    }
  };

  const numberFormater = new Intl.NumberFormat('en-US', options[format]);

  return Number.isFinite(target)
    ? numberFormater.format(target)
    : numberFormater.format(0);
};

export const formatedNumberToNumber = target =>
  Number(target.replace(/[^0-9.-]+/g, ''));
