import Decimal from 'decimal.js';
import { Maths } from './maths';
import { roundValue, roundValueTo3Decimals } from './transformHelpers';
import { getConvertUnitFromTo } from './unitConversionHelpers';

function calAreaValue(product, newAreaValue) {
  return Maths.multiplyRound(
    Maths.divide(product.areaValue, newAreaValue),
    100,
    2,
    Decimal.ROUND_HALF_UP
  ) === product.coveragePercent
    ? product.areaValue
    : Maths.divideSafeRound(
        Maths.multiply(newAreaValue, product.coveragePercent),
        100,
        2,
        Decimal.ROUND_HALF_UP
      );
}

export const calculateAppliedArea = (product, newAreaValue) => {
  let totalProdValue;
  let totalProdCost;
  const totalProdUnit = product.specificCostUnit;
  const unit = product.productRateUnit;
  const rate = product.productRateValue;
  const price = product.specificCostValue;
  const appArea = calAreaValue(product, newAreaValue);

  const numApps = product.applicationCount;

  if (unit === totalProdUnit) {
    totalProdValue = rate * appArea * numApps;
    totalProdCost = totalProdValue * price;
  } else {
    const conversion = getConvertUnitFromTo(
      Number.parseFloat(rate),
      product.productRateUnit,
      product.specificCostUnit,
      product
    );
    totalProdValue =
      roundValueTo3Decimals(conversion.Value) * appArea * numApps;
    totalProdCost = totalProdValue * price;
  }

  return {
    ...product,
    areaValue: appArea,
    totalProductCost: roundValue(totalProdCost),
    totalProductValue: totalProdValue
  };
};

export default calculateAppliedArea;
