import React from 'react';
import SatelliteIcon from 'components/Icons/SatelliteIcon';
import TrafficLightIcon from 'components/Icons/TrafficLightIcon';
import { Radio } from 'syngenta-digital-cropwise-react-ui-kit';
import { MAP_STYLE_TYPES } from 'helpers/constants';
import PropTypes from 'prop-types';

const MapStyleRadioGroup = ({ mapStyleLocalValue, handleRadioChange }) => {
  return (
    <div className="mb-2 w-full">
      <Radio.Group
        name="background-image"
        className="flex flex-col"
        value={mapStyleLocalValue}
        onChange={handleRadioChange}
      >
        <Radio.Button
          value={MAP_STYLE_TYPES.satellite}
          className="flex items-center"
        >
          <div className="relative">
            <div className="flex items-center pt-1">
              <SatelliteIcon />
              <span style={{ marginLeft: '1px' }}>Satellite</span>
            </div>
          </div>
        </Radio.Button>

        <Radio.Button
          value={MAP_STYLE_TYPES.street}
          className="flex items-center"
        >
          <div className="relative">
            <div className="flex items-center mt-3 mb-2">
              <TrafficLightIcon />
              <span style={{ marginLeft: '1px' }}>Road map</span>
            </div>
          </div>
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

MapStyleRadioGroup.propTypes = {
  mapStyleLocalValue: PropTypes.string.isRequired,
  handleRadioChange: PropTypes.func.isRequired
};

export default MapStyleRadioGroup;
