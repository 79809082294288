import { useCallback, useContext, useMemo, useState } from 'react';
import { SET_PROPERTY_FILTERS } from 'reducers/reducer';
import {
  FILTER_TYPE,
  RADIO_OPTIONS_FLAGS
} from 'screens/Property/PropertiesLanding/components/PropertyTreeView/components/PropertyFilters/constants';
import _ from 'lodash';
import createAction from 'helpers/createAction';
import { Context } from '../components/Store';

const usePropertyFilters = (type, isHugeOrganization = false) => {
  const [{ propertyFilters }, dispatch] = useContext(Context);
  const { showArchivedProperties, showFarmWithIds, tasksFiltersConfig } =
    propertyFilters || {};
  const [properties, setProperties] = useState([]);
  const [tasksTags, setTasksTags] = useState([]);
  const { showTaskTagsWithIds } = tasksFiltersConfig || {};

  const filterOutArchivedProperties = propertyList => {
    return (
      propertyList
        ?.filter(property => !property.archived)
        .map(property => ({
          ...property,
          fields: property.fields?.filter(field => !field.archived) || []
        })) || []
    );
  };

  const sortAndIncludeArchivedProperties = useCallback(
    propertyList => {
      if (!propertyList) return [];
      const sort = (arr, sortFn) =>
        isHugeOrganization ? arr : arr.sort(sortFn);
      return sort(
        propertyList,
        (propertyA, propertyB) => propertyA.archived - propertyB.archived
      ).map(property =>
        property.fields
          ? {
              ...property,
              fields: property.fields.sort(
                (fieldA, fieldB) => fieldA.archived - fieldB.archived
              )
            }
          : property
      );
    },
    [isHugeOrganization]
  );

  const propertiesFilter = useMemo(() => {
    let propertyList;
    if (showArchivedProperties) {
      propertyList = sortAndIncludeArchivedProperties(properties);
    } else {
      propertyList = filterOutArchivedProperties(properties);
    }

    if (showFarmWithIds?.length > 0) {
      propertyList = propertyList.filter(property =>
        showFarmWithIds?.includes(property.id)
      );
    }
    return propertyList;
  }, [
    showArchivedProperties,
    properties,
    showFarmWithIds,
    sortAndIncludeArchivedProperties
  ]);

  const taskTagsFilter = useMemo(() => {
    if (!showTaskTagsWithIds || showTaskTagsWithIds.length === 0) {
      return tasksTags;
    }
    if (tasksFiltersConfig.radioOption === RADIO_OPTIONS_FLAGS.anyTags) {
      return tasksTags.filter(task =>
        task.flags?.some(flag => showTaskTagsWithIds.includes(flag.id))
      );
    }
    if (tasksFiltersConfig.radioOption === RADIO_OPTIONS_FLAGS.allTags) {
      return tasksTags.filter(
        task =>
          task.flags?.every(flag => showTaskTagsWithIds.includes(flag.id)) &&
          task.flags?.length === showTaskTagsWithIds.length
      );
    }
    return [];
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tasksTags, tasksFiltersConfig]);

  const setShowArchivedProperties = enable => {
    dispatch({
      type: 'SET_PROPERTY_FILTERS',
      payload: {
        showArchivedProperties: enable
      }
    });
  };

  const setFarmsFilter = ids => {
    dispatch({
      type: 'SET_PROPERTY_FILTERS',
      payload: {
        showFarmWithIds: ids
      }
    });
  };

  const setTaskTagsFilter = newTasksFiltersConfig => {
    createAction(dispatch, SET_PROPERTY_FILTERS, {
      tasksFiltersConfig: {
        radioOption: newTasksFiltersConfig.radioOption,
        showTaskTagsWithIds: newTasksFiltersConfig.showTaskTagsWithIds
      }
    });
  };

  const runMapFilter = useCallback(
    list => {
      if (showArchivedProperties) {
        return list;
      }

      return list.features
        ? {
            ...list,
            features: list.features.filter(f => !f.properties?.archived)
          }
        : [];
    },
    [showArchivedProperties]
  );

  const hasActiveFilters = () => {
    if (type === FILTER_TYPE.reports) {
      return showTaskTagsWithIds.length > 0;
    }
    return (
      showArchivedProperties ||
      properties?.length > 0 ||
      showFarmWithIds?.length > 0
    );
  };

  const hasFilterChanges = ({
    selectedFarmIds,
    showLocalArchivedProperties,
    localTasksFiltersConfig
  }) =>
    !_.isEqual(_.sortBy(selectedFarmIds), _.sortBy(showFarmWithIds)) ||
    showLocalArchivedProperties !== showArchivedProperties ||
    !_.isEqual(localTasksFiltersConfig, tasksFiltersConfig);

  const applyFilters = ({
    selectedFarmIds,
    showLocalArchivedProperties,
    newConfigTaskTagsFilter
  }) => {
    setFarmsFilter(selectedFarmIds);
    setShowArchivedProperties(showLocalArchivedProperties);
    setTaskTagsFilter(newConfigTaskTagsFilter);
  };

  return {
    filteredProperties: propertiesFilter || [],
    filteredTasksByTags: taskTagsFilter,
    showArchivedProperties,
    setShowArchivedProperties,
    setPropertiesToFilter: setProperties,
    setTaskTagsToFilter: setTasksTags,
    hasActiveFilters,
    hasFilterChanges,
    runMapFilter,
    applyFilters
  };
};

export default usePropertyFilters;
