import catchCancel from 'helpers/catchCancel';
import { calculateAppliedArea } from 'helpers/calculateAppliedArea';
import { products as productsApi, product as productApi } from 'utilities/api';

const useMasterProducts = () => {
  const getStdUnits = async product => {
    const { promise } =
      product.custom === true
        ? productApi.fetch(product.productId)
        : productsApi.fetch(product.productId);
    const { data } = await promise.catch(catchCancel).catch(() => ({
      data: {
        stdfactor: product.productRateValue,
        stdunit: product.productRateUnit,
        stdpackageunit: product.specificCostUnit,
        density: product.density
      }
    }));
    if (data.stdFactor === 0) {
      data.stdFactor = 1;
    } else if (data.stdfactor === 0) {
      data.stdfactor = 1;
    }

    return {
      ...product,
      density: data.density,
      stdfactor: product.custom === true ? data.stdFactor : data.stdfactor,
      stdunit:
        product.custom === true
          ? data.standardUnit
          : data.stdunit || data.stdUnit,
      stdpackageunit:
        product.custom === true ? data.stdPackageUnit : data.stdpackageunit
    };
  };

  const appliedAreaCalculation = async (product, unmaskedAreaValue) => {
    const { stdfactor, stdunit, stdpackageunit } = product;
    let mappedProduct = product;

    if (!stdfactor || !stdunit || !stdpackageunit) {
      mappedProduct = await getStdUnits(product);
    }

    return calculateAppliedArea(mappedProduct, unmaskedAreaValue);
  };

  return { appliedAreaCalculation, getStdUnits };
};

export default useMasterProducts;
