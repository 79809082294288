import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

const UpdateReportButton = ({ disabled }) => {
  const { values, handleSubmit } = useFormikContext();
  const handleClick = () => {
    handleSubmit(values);
  };
  return (
    <div className="w-full flex justify-end p-10px sticky bottom-0 bg-white border-t-1 border-neutral-20">
      <Button type="primary" disabled={disabled} onClick={handleClick}>
        Update Report
      </Button>
    </div>
  );
};

UpdateReportButton.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default UpdateReportButton;
