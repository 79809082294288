import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { Context, initialState } from 'components/Store';
import CollapsibleToggle from 'components/CollapsibleToggle';
import usePropertyFilters from 'hooks/usePropertyFilters';
import FarmsFilter from './components/FarmsFilter';
import TasksTagFilter from './components/TasksTagFilter';
import { FILTER_TYPE, RADIO_OPTIONS_FLAGS } from './constants';

const PropertyFilters = ({ properties, tasksData, onClose, type }) => {
  const [{ organization, propertyFilters, isExportPDFMapsActive }] = useContext(
    Context
  );
  const flags = useFlags();
  const feArchiveField =
    flags.feArchiveField ||
    (flags.feArchiveFieldOrgIds &&
      flags.feArchiveFieldOrgIds.indexOf(organization?.id) !== -1);

  const feFarmsFilter = true;
  const amplitude = useContext(AmplitudeContext);
  const {
    showArchivedProperties,
    hasFilterChanges,
    applyFilters
  } = usePropertyFilters(type, undefined);

  const [
    showLocalArchivedProperties,
    setShowLocalArchivedProperties
  ] = useState(showArchivedProperties);

  const [selectedFarmIds, setSelectedFarmIds] = useState(
    propertyFilters?.showFarmWithIds || []
  );

  const [localTasksFiltersConfig, setLocalTasksFiltersConfig] = useState({
    ...propertyFilters?.tasksFiltersConfig
  });

  const hasSelectedFilters = () => {
    if (type === FILTER_TYPE.reports) {
      return (
        !!localTasksFiltersConfig.showTaskTagsWithIds.length ||
        localTasksFiltersConfig.radioOption !== RADIO_OPTIONS_FLAGS.anyTags
      );
    }
    return showLocalArchivedProperties || !!selectedFarmIds.length;
  };

  const isFilterTouched = () =>
    hasFilterChanges({
      selectedFarmIds,
      showLocalArchivedProperties,
      localTasksFiltersConfig
    });

  const onFarmSelect = id => () => {
    if (selectedFarmIds.includes(id)) {
      setSelectedFarmIds(selectedFarmIds.filter(farmId => farmId !== id));
    } else {
      setSelectedFarmIds([...selectedFarmIds, id]);
    }
  };

  const handleRadioButtonGroup = radioButton => {
    setLocalTasksFiltersConfig({
      ...localTasksFiltersConfig,
      radioOption: radioButton.target.value
    });
  };

  const toggleArchiveField = value => {
    if (!value) {
      // If archived farms are toggled off, de-select all archived farms in farm filter
      const archivedFarmIds = properties
        .filter(farm => farm.archived)
        .map(farm => farm.id);
      setSelectedFarmIds(_.difference(selectedFarmIds, archivedFarmIds));
    }
    setShowLocalArchivedProperties(value);
  };

  const onApply = () => {
    applyFilters({
      showLocalArchivedProperties,
      selectedFarmIds,
      newConfigTaskTagsFilter: localTasksFiltersConfig
    });
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Properties.filters.toggleArchivedProperties,
      {
        localArchivedProperties: showLocalArchivedProperties
      }
    );
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  const onClearAll = () => {
    setSelectedFarmIds([]);
    setShowLocalArchivedProperties(false);
    setLocalTasksFiltersConfig(initialState.propertyFilters.tasksFiltersConfig);
  };

  const handleSetSelectedTasksTagIds = checked => {
    setLocalTasksFiltersConfig({
      ...localTasksFiltersConfig,
      showTaskTagsWithIds: checked
    });
  };

  return (
    <div
      data-tree
      className="relative flex-grow flex flex-col h-full bg-white"
      data-testid="mass-assigner-tree"
    >
      <div className="flex-1 flex flex-col h-full">
        <div className="w-full px-5 flex flex-row justify-between items-center sticky top-0 bg-white pt-6 pb-10">
          <h1 className="text-3xl font-semibold">Filter</h1>
          <Button
            data-testid="clear-all-filters-button"
            type={ButtonType.outline}
            size="small"
            onClick={onClearAll}
            disabled={!hasSelectedFilters()}
          >
            Clear All
          </Button>
        </div>
        <div className="mb-16 overflow-y-auto">
          {type === FILTER_TYPE.reports && tasksData.length ? (
            <TasksTagFilter
              tasksData={tasksData}
              handleRadioButtonGroup={handleRadioButtonGroup}
              localTasksFiltersConfig={localTasksFiltersConfig}
              setSelectedTasksTagIds={handleSetSelectedTasksTagIds}
            />
          ) : (
            <>
              {feFarmsFilter && (
                <FarmsFilter
                  farms={properties}
                  selectedFarmIds={selectedFarmIds}
                  onFarmSelect={onFarmSelect}
                  showArchived={showLocalArchivedProperties}
                />
              )}
              {feArchiveField && !isExportPDFMapsActive && (
                <CollapsibleToggle
                  options={[
                    {
                      name: 'Show archived properties in field list',
                      onChange: toggleArchiveField,
                      value: showLocalArchivedProperties
                    }
                  ]}
                  title="Archived"
                />
              )}
            </>
          )}
        </div>
        <div className="flex flex-row w-full gap-x-2 p-4 justify-between absolute left-0 bottom-0 bg-white border-t-1 border-neutral-20">
          <Button onClick={onCancel} type={ButtonType.outline}>
            Cancel
          </Button>
          <Button
            data-testid="apply-fft-filters-button"
            onClick={onApply}
            type={ButtonType.primary}
            disabled={!isFilterTouched()}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

PropertyFilters.defaultProps = {
  properties: [],
  tasksData: [],
  type: ''
};

PropertyFilters.propTypes = {
  onClose: PropTypes.func.isRequired,
  tasksData: PropTypes.arrayOf(PropTypes.shape()),
  properties: PropTypes.arrayOf(PropTypes.shape()),
  type: PropTypes.string
};

export default PropertyFilters;
