import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Input, Table, Select, Toast, Spinner } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import { intersection } from 'lodash';
import PropTypes from 'prop-types';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import FieldIcon from 'components/Icons/FieldIcon';
import MapIllustration from 'assets/properties-map-icon.svg';
import { calculateProductValues } from 'helpers/unitConversionHelpers';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';
import ChecklistProperty from 'screens/CropSeasons/CropSeason/components/FormSection/GroupByFilterProperty';
import ChecklistCrop from 'screens/CropSeasons/CropSeason/components/FormSection/GroupByFilterCrop';
import { getApplicationType } from 'screens/Tasks/helpers/dataHelpers';
import { adjustZoneAcreValues } from 'utilities/helpers';
import usePropertyData from 'hooks/usePropertyData';
import useMasterProducts from 'hooks/useMasterProducts';
import { addSelectedCropSeasonsToFarms } from 'helpers/propertyHelpers';
import { filterArchived, getArchivedCropZones } from 'helpers/archivedHelpers';
import { Maths } from 'helpers/maths';
import { TAB_OPTIONS } from 'utilities/menus';
import EllipseMenu from 'components/EllipseMenu';
import formatNumber from 'helpers/formatNumber';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import Decimal from 'decimal.js';
import {
  roundAreaToPercent,
  hydrateProperties,
  getSelectedCropZones
} from './helpers';
import EditPropertyModal from './components/EditPropertyModal';

const Properties = ({
  propertyLandingPageData,
  loading,
  fieldsAndAreasGeoJSONCollection,
  cropSeasonIds,
  selectedApplicationOption,
  setAmplitudeTabSelection
}) => {
  const amplitude = useContext(AmplitudeContext);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [newAreaValueSum, setUpdateTotalAreaForProducts] = useState();
  const { getStdUnits } = useMasterProducts();
  const [selectedCropzoneIds, setSelectedCropzoneIds] = useState(
    values.properties?.map(cz => cz.cropZoneId)
  );
  const [editingProperty, setEditingProperty] = useState(null);
  const [noCropSeason, setNoCropSeason] = useState(cropSeasonIds[0] === null);
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);
  const [originalCropzonesValues] = useState(values.properties.length);
  const [initialCropzoneIds] = useState(
    values.properties?.map(cz => cz.cropZoneId)
  );
  const [archivedCropZones] = useState(
    getArchivedCropZones(propertyLandingPageData)
  );
  const [filteredByArchivedPropertyLandingPageData] = useState(
    filterArchived(propertyLandingPageData, initialCropzoneIds)
  );
  const { originalTotalArea, setProperties } = usePropertyData();

  const properties = addSelectedCropSeasonsToFarms(
    filteredByArchivedPropertyLandingPageData,
    cropSeasonIds
  );

  const selectedCropzones = useMemo(() => {
    return getSelectedCropZones(
      properties,
      selectedCropzoneIds,
      fieldsAndAreasGeoJSONCollection,
      values
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropzoneIds, loading]);

  const modalType = useMemo(
    () => getApplicationType(selectedApplicationOption),
    [selectedApplicationOption]
  );

  const checkForFFTChanges = () => {
    return (
      originalCropzonesValues !== selectedCropzoneIds.length &&
      setFieldTouched('FFT Changed')
    );
  };

  const removeCropzoneIdsOnSeasonChange = cropSeasons => {
    if (selectedCropzoneIds.length > 0 && values) {
      const allCropZoneIds = values.properties
        .filter(cz => intersection(cz.cropSeasonIds, cropSeasons).length > 0)
        .map(({ cropZoneId }) => cropZoneId);
      const currentlySelectedCropZoneIds = [...new Set(allCropZoneIds)];
      if (currentlySelectedCropZoneIds.length) {
        setSelectedCropzoneIds(currentlySelectedCropZoneIds);
      }
    }
  };

  useEffect(() => {
    if (cropSeasonIds[0] === null) {
      setNoCropSeason(true);
      setToastRenderContents(
        'Please select a cropseason before selecting a property.'
      );
      setToastHasNotBeenClosed(true);
    } else {
      setNoCropSeason(false);
    }
    removeCropzoneIdsOnSeasonChange(cropSeasonIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasonIds]);

  useEffect(() => {
    checkForFFTChanges();
    if (selectedCropzones) {
      const props = hydrateProperties(selectedCropzones, values);
      if (props !== null) {
        setFieldValue('properties', props);
        setProperties(props);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropzones, setFieldValue]);

  const getTotalArea = () => {
    const totalArea = values.properties.reduce((acc, curr) => {
      return acc + curr.areaValue;
    }, 0);
    return Maths.parseFloatRound(totalArea, 2, Decimal.ROUND_HALF_UP);
  };

  useEffect(() => {
    const totalArea = getTotalArea();
    setFieldValue('totalApplied', totalArea);
    setFieldValue('totalAreaValue', totalArea);
    setUpdateTotalAreaForProducts(totalArea);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.properties]);

  const calProductArea = prod => {
    return Maths.parseFloatRound(
      Maths.multiply(
        Number.parseFloat(newAreaValueSum),
        Maths.divide(prod.coveragePercent, 100)
      ),
      2,
      Decimal.ROUND_HALF_UP
    );
  };

  useEffect(() => {
    Promise.all(
      values?.products?.map(async prod => {
        const acresChangedValues = {
          changedArea: calProductArea(prod)
        };

        let product = prod;

        if (!prod.stdunit || !prod.stdfactor || !prod.stdpackageunit) {
          product = await getStdUnits(prod);
        }

        return calculateProductValues(
          modalType.key,
          prod[modalType.value],
          {},
          acresChangedValues,
          undefined,
          product,
          values
        );
      })
    ).then(mappedProducts => setFieldValue('products', mappedProducts));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType, newAreaValueSum, values.totalApplied, originalTotalArea]);

  useEffect(() => {
    const newPercent = roundAreaToPercent(
      values.totalApplied,
      originalTotalArea
    );
    if (
      newPercent !== Number(values.coveragePercent) &&
      !Number.isNaN(newPercent) &&
      Number.isFinite(newPercent)
    ) {
      setFieldValue('coveragePercent', newPercent);
    }
  }, [
    values.totalApplied,
    originalTotalArea,
    setFieldValue,
    values.coveragePercent
  ]);

  useEffect(() => {
    setAmplitudeTabSelection('Properties');
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Tasks.selectPropertiesTab
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePropertySelect = cropzones => {
    setSelectedCropzoneIds(cropzones);
    if (cropzones.length === 0) {
      setFieldValue('totalApplied', 0);
      setFieldValue('totalAreaValue', 0);
      setFieldValue('coveragePercent', 100);
      setFieldValue('properties', []);
    }
  };

  const handleChange = e => {
    const dropDownValue = JSON.parse(e.target.value);
    setFieldValue('propertiesGroupBy', dropDownValue.key);
  };

  const handleAction = (action, property) => {
    const actions = {
      edit: () =>
        setEditingProperty({
          ...property,
          areaType: property.reportedArea ? property.areaType : 'boundary'
        }),
      remove: () => {
        setFieldTouched('delete cropzone');
        handlePropertySelect(map =>
          map.filter(item => item !== property.cropZoneId)
        );
      }
    };
    actions[action]();
  };

  return (
    <div className="-mx-2 -mr-6">
      <div className="flex justify-end mb-6">
        <div className="mr-6">
          {toastHasNotBeenClosed ? (
            <Toast
              icon="error"
              onClose={() => {
                setToastHasNotBeenClosed(false);
              }}
            >
              {toastRenderContents}
            </Toast>
          ) : null}
          <GrowUIFormField
            label="Total Applied"
            control={Input}
            name="totalApplied"
            disabled={
              values.properties.length === 0 || values.coveragePercent === 0
            }
            onBlur={e => {
              setFieldTouched('Total Applied');
              setFieldValue('totalApplied', Number(e.target.value));
              if (Number(e.target.value) !== Number(e.target.defaultValue)) {
                const newPercent = roundAreaToPercent(
                  Number(e.target.value),
                  originalTotalArea
                );

                setFieldValue(
                  'properties',
                  adjustZoneAcreValues(
                    newPercent,
                    roundAreaToPercent(
                      Number(e.target.defaultValue),
                      originalTotalArea
                    ),
                    values.properties
                  )
                );
                setFieldValue('coveragePercent', newPercent);
              }
            }}
            imask={{
              mask: 'num',
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  scale: 2,
                  signed: false,
                  thousandsSeparator: '',
                  padFractionalZeros: false,
                  normalizeZeros: true,
                  radix: '.',
                  mapToRadix: [','],
                  min: 0
                }
              }
            }}
          />
        </div>
        <GrowUIFormField
          label="Coverage %"
          control={Input}
          name="coveragePercent"
          disabled={values.properties.length === 0}
          onBlur={e => {
            setFieldTouched('Coverage %');
            setFieldValue('coveragePercent', Number(e.target.value));
            if (Number(e.target.value) !== Number(e.target.defaultValue)) {
              setFieldValue(
                'properties',
                adjustZoneAcreValues(
                  Number(e.target.value),
                  Number(e.target.defaultValue),
                  values.properties
                )
              );
            }
          }}
          imask={{
            mask: 'num',
            lazy: false,
            blocks: {
              num: {
                mask: Number,
                scale: 2,
                signed: false,
                thousandsSeparator: '',
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: '.',
                mapToRadix: [','],
                min: 0
              }
            }
          }}
        />
      </div>
      <div className="flex">
        <div className="w-1/4 pr-8">
          <div className="mb-3">
            {/* <div className="mb-4 text-sm font-semibold uppercase">Farms</div> */}
            {!loading ? (
              <>
                <GrowUIFormField
                  control={Select}
                  name="propertiesGroupBy"
                  items={[
                    { key: 'property', value: 'Group By Farm' },
                    { key: 'crop', value: 'Group By Crop' }
                  ]}
                  onChange={e => {
                    handleChange(e);
                  }}
                />

                {values?.propertiesGroupBy === 'property' ? (
                  <ChecklistProperty
                    data={properties}
                    onChange={handlePropertySelect}
                    value={values.properties}
                    cropZonesChecked={selectedCropzoneIds}
                    setCropZonesChecked={setSelectedCropzoneIds}
                    disabled={noCropSeason}
                    // cropSeasonIds={cropSeasonIds}
                  />
                ) : (
                  <ChecklistCrop
                    data={properties}
                    onChange={handlePropertySelect}
                    value={values.properties}
                    cropZonesChecked={selectedCropzoneIds}
                    setCropZonesChecked={setSelectedCropzoneIds}
                    disabled={noCropSeason}
                  />
                )}
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
        <div className="flex flex-col w-3/4">
          <div className="flex flex-col h-full">
            <Table>
              <Table.Header>
                <Table.Cell width="30%">
                  Fields ({values?.properties?.length})
                </Table.Cell>
                <Table.Cell>Crops</Table.Cell>
                <Table.Cell>Area</Table.Cell>
                <Table.Cell>Coverage %</Table.Cell>
                <Table.Cell />
              </Table.Header>
              {values.properties?.map(property => {
                const ft = property.features?.[0]
                  ? [mappingShape(property.features[0])]
                  : undefined;
                const isPropertyArchived =
                  archivedCropZones.indexOf(property.cropZoneId) !== -1;

                return (
                  <Table.Row key={property.cropZoneId}>
                    <Table.Cell>
                      <div className="flex items-center">
                        <FieldIcon id="field-card-icon" features={ft} />
                        <div className="flex flex-col justify-start leading-5 text-left">
                          <span className="font-bold text-neutral-1000">
                            {property.fieldName}
                            {isPropertyArchived ? ' (archived) ' : ''}
                          </span>
                          <span style={{ color: '#707374' }}>
                            {property.name}
                          </span>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{property.crop?.name}</Table.Cell>
                    <Table.Cell>
                      {formatNumber(property.areaValue, property.areaUnit)}
                    </Table.Cell>
                    <Table.Cell>
                      {Math.floor(property.coveragePct * 100) / 100}
                    </Table.Cell>
                    <Table.Cell>
                      <EllipseMenu
                        data-testid={`ellipse-menu-${property.cropZoneId}`}
                        onAction={action => handleAction(action, property)}
                        options={TAB_OPTIONS}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table>
            {!values.properties?.length && (
              <div className="flex flex-col items-center self-stretch justify-center h-full py-32 border border-t-0">
                <img src={MapIllustration} alt="No properties illustration" />
                <p>
                  Select one or more fields from the list to add it to this Task
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <EditPropertyModal
        editingProperty={editingProperty}
        setEditingProperty={setEditingProperty}
      />
    </div>
  );
};

Properties.propTypes = {
  propertyLandingPageData: PropTypes.arrayOf(
    PropTypes.shape({
      cropZoneId: PropTypes.string.isRequired,
      fieldName: PropTypes.string,
      name: PropTypes.string,
      crop: PropTypes.shape({
        name: PropTypes.string
      }),
      areaValue: PropTypes.number,
      areaUnit: PropTypes.string,
      coveragePct: PropTypes.number,
      features: PropTypes.arrayOf
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.objectOf.isRequired,
  cropSeasonIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedApplicationOption: PropTypes.string.isRequired,
  setAmplitudeTabSelection: PropTypes.func.isRequired
};

export default Properties;
